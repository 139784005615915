import React from 'react';
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper l06nfmc62ma-editor_css',
    playScale: 0.3,
    appear: true,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        ANGRY ANT<br />
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Angry Ant
                          隶属于广州好掌信息科技有限公司开源产品，公司研发团队项目经验丰富，把项目中遇到的通用需求都集成到框架中，
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>打造一个开箱即用的企业级低代码开发平台！</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content~l06wihrrzlr',
        className: '',
        children: (
          <span>
            <span>
              <span>
                <p>
                  <b>商务合作QQ： 584042976、1126505489、563284570</b>
                </p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l06wl50ggo8',
        className: '',
        children: (
          <span>
            <span>
              <span>
                <p>
                  <b>邮箱：1126505489@qq.com</b>
                  <br />
                </p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content~l06wn44iawo',
        className: '',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    <b>开源交流群：XXXXX</b>
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: '#',
        children: (
          <span>
            <p>立即体验</p>
          </span>
        ),
      },
    },
  },
};
